import clsx from 'clsx';
import { Fragment } from 'react';

const BasicInfo = ({ model }) => {
  const columns = [
    ['Model Type', 'Process', 'Scale', 'Developer', 'Scopes', 'Type of Output'],
    ['Information Produced', 'Availability', 'NFIP Approved', 'Phase'],
  ];

  const listColumns = [
    'Process',
    'Scale',
    'Developer',
    'Scopes',
    'Type of Output',
    'Information Produced',
    'Phase',
  ];

  return (
    <div className="flex flex-wrap justify-between gap-0 md:flex-nowrap md:gap-8">
      {columns.map((column, index) => (
        <div
          key={index}
          className={clsx(
            'flex flex-col w-[95%] self-start md:grid-cols-[auto_1fr] md:gap-8 md:grid md:w-full',
            columns.length === 2 ? 'max-w-lg md:w-1/2' : '',
          )}
        >
          {column.map((row, i) => {
            if (!row[0]) {
              return (
                <Fragment key={i}>
                  <div className="h-6"></div>
                  <div></div>
                </Fragment>
              );
            }
            return (
              <Fragment key={row}>
                <h2 className="mb-4 md:mb-0 font-bold">{row}:</h2>
                {listColumns.includes(row) ? (
                  model[row].split(';').length > 1 ? (
                    <ul className="mb-10 md:mb-0">
                      {model[row].split(';').map((value, index) => (
                        <li key={`${model[row]}-${index}`} className="mt-0 mb-2 ml-4">
                          {value}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="mb-10 md:mb-0">{model[row] || '--'}</p>
                  )
                ) : (
                  <p className="mb-10 md:mb-0">{model[row] || '--'}</p>
                )}
              </Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default BasicInfo;
