import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/solid';
import Button from './Button';
import tdisLogo from '../../assets/tdis-logo-blue.svg';

const Header = () => {
  const [mobileView, setMobileView] = useState(window.innerWidth < 850);
  const [menuOpened, setMenuOpened] = useState(false);

  window.addEventListener('resize', () => {
    window.innerWidth < 850 ? setMobileView(true) : setMobileView(false);
  });

  const menuNavClass = 'mx-6 my-3 block uppercase no-underline text-ice font-thin';
  const navClass = 'text-lg uppercase no-underline hover:text-white hover:bg-sage p-2';

  const headerHeightTw = 'h-28';
  const headerTopTw = 'top-48';

  return (
    <header>
      <div
        className={`${headerHeightTw} z-10 flex w-full items-center justify-between bg-white border-b border-b-primary`}
      >
        <div className="flex w-full items-center justify-between px-8 md:px-12">
          <a href="https://idrt.tamug.edu/tdis/" target="_blank" rel="noreferrer">
            <img src={tdisLogo} alt="TDIS Logo" className="h-14 md:h-16" />
          </a>

          {mobileView ? (
            <>
              <button
                aria-label="Open Menu"
                className="flex cursor-pointer"
                onClick={() => setMenuOpened(!menuOpened)}
              >
                <Bars3Icon className="w-10" />
              </button>
              {menuOpened && (
                <nav
                  className={`absolute z-10 left-0 w-full border-b border-gray bg-primary text-xl shadow ${headerTopTw}`}
                >
                  <NavLink className={menuNavClass} to="/" onClick={() => setMenuOpened(false)}>
                    Home
                  </NavLink>

                  <NavLink
                    className={menuNavClass}
                    to="/about"
                    onClick={() => setMenuOpened(false)}
                  >
                    About
                  </NavLink>

                  <NavLink
                    className={menuNavClass}
                    to="/methodology"
                    onClick={() => setMenuOpened(false)}
                  >
                    Methodology
                  </NavLink>

                  <a
                    className={menuNavClass}
                    href="mailto:tdis@tamu.edu?subject=M3FR%20website%20feedback"
                    rel="noreferrer"
                    onClick={() => setMenuOpened(false)}
                  >
                    Report Feedback
                  </a>
                </nav>
              )}
            </>
          ) : (
            <nav className="flex items-center gap-4 md:gap-10">
              <NavLink className={navClass} to="/">
                Home
              </NavLink>

              <NavLink className={navClass} to="/about">
                About
              </NavLink>

              <NavLink className={navClass} to="/methodology">
                Methodology
              </NavLink>

              <a
                href="mailto:tdis@tamu.edu?subject=M3FR%20website%20feedback"
                target="_blank"
                rel="noreferrer"
                className="no-underline"
              >
                <Button transparent small>
                  Report Feedback
                </Button>
              </a>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
