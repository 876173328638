import './loaders.css';

const LoadingDots = ({ inverted }) => {
  const className = inverted ? '!bg-white' : 'after:!bg-[#0C4A6E]';
  return (
    <>
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
    </>
  );
};

// Full-page loader, with circulating dots
export const PageLoader = () => (
  <div className="fixed z-[100] flex h-full w-full items-center justify-center">
    <div className="lds-roller">
      <LoadingDots />
    </div>
  </div>
);

// Section loader, with circulating dots
export const SectionLoader = ({ inverted }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="lds-roller">
        <LoadingDots inverted={inverted} />
      </div>
    </div>
  );
};

// Button loader, with animated dots in a line
export const ButtonLoader = () => {
  const className = 'after:!bg-[#0C4A6E]';
  return (
    <div className="lds-ellipsis" data-testid="button-loader">
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
    </div>
  );
};
