import { Tooltip } from 'react-tooltip';
import { NavLink } from 'react-router-dom';

export const HydrodynamicTooltip = () => {
  return (
    <Tooltip id="Hydrodynamic" clickable place="right">
      <p className="text-white max-w-96">
        Hydrodynamic models have the capability of simulating both the hydrologic and hydraulic
        processes of an area of interest, they can simulate the rainfall-runoff processes as well as
        the flood routing within water bodies.
      </p>
      <NavLink className="text-white" to="/methodology#hydrodynamic">
        Read more
      </NavLink>
    </Tooltip>
  );
};

export const HydrologicTooltip = () => {
  return (
    <Tooltip id="Hydrologic" clickable place="right">
      <p className="text-white max-w-96">
        Hydrologic models simulate the various processes of the hydrologic cycle, including
        rainfall, surface runoff, losses, and flood storage, either natural or manmade.
      </p>
      <NavLink className="text-white" to="/methodology#hydrologic">
        Read more
      </NavLink>
    </Tooltip>
  );
};

export const HydraulicTooltip = () => {
  return (
    <Tooltip id="Hydraulic" clickable place="right">
      <p className="text-white max-w-96">
        Hydraulic models simulate the flow, conveyance, and routing of fluid in both open-channels
        and closed systems.
      </p>
      <NavLink className="text-white" to="/methodology#hydraulic">
        Read more
      </NavLink>
    </Tooltip>
  );
};

export const NonPhysicsTooltip = () => {
  return (
    <Tooltip id="Simplified/Non-Physics" clickable place="right">
      <p className="text-white max-w-96">
        Non-Physics models include any types of models not driven / governed by physical processes.
      </p>
      <NavLink className="text-white" to="/methodology#simplified">
        Read more
      </NavLink>
    </Tooltip>
  );
};

export const ScaleInfoTooltip = () => {
  return (
    <Tooltip id="Scale" place="right">
      <ol>
        <li>Site-scale/Local</li>
        <li>Watershed</li>
        <li>Multi-watershed/Regional</li>
        <li>Continental</li>
      </ol>
    </Tooltip>
  );
};
