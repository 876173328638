import { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { modelDataSelector } from '../store/selectors/general';
import { SectionLoader } from './general/Loaders';
import { MODEL_IMAGES } from '../constants/general';
import TextArea from './general/TextArea';
import BasicInfo from './tabs/BasicInfo';
import StrengthsAndWeaknesses from './tabs/StrengthsAndWeaknesses';
import Resources from './tabs/Resources';
import Gallery from './tabs/Gallery';

const ModelPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [modelImages, setModelImages] = useState([]);
  const [modelLogos, setModelLogos] = useState([]);

  const allModels = useSelector(modelDataSelector);

  useEffect(() => {
    if (allModels) {
      setModel(allModels.filter(m => m['Number'] === id)[0]);
    }
  }, [allModels, id]);

  useEffect(() => {
    setIsLoading(true);
    // Select all the images and logos from a particular model based on their model number
    if (model && model['Number']) {
      const modelResources = MODEL_IMAGES.find(({ id }) => model['Number'] === id);
      if (modelResources) {
        setModelImages(modelResources.images);
        setModelLogos(modelResources.logos);
      }
    }
    setIsLoading(false);
  }, [model]);

  const tabs = [
    {
      label: 'Basic Info',
      value: 1,
      component: <BasicInfo model={model} />,
    },
    {
      label: 'Strengths & Weaknesses',
      value: 2,
      component: <StrengthsAndWeaknesses model={model} />,
    },
    {
      label: 'Resources',
      value: 3,
      component: <Resources model={model} />,
    },
    {
      label: 'Gallery',
      value: 4,
      component: <Gallery modelImages={modelImages} isLoading={isLoading} />,
    },
  ];

  return (
    <div className="min-h-screen bg-primary">
      {!model ? (
        <div className="flex flex-col pt-20 items-center">
          <SectionLoader inverted={true} />
          <p className="mt-4 text-white">Loading model...</p>
        </div>
      ) : (
        <main className="p-6 md:p-20">
          <div className="flex flex-col justify-between md:flex-row gap-10">
            <div className="flex flex-col gap-6 md:items-center md:flex-row">
              <button
                onClick={() => navigate('/')}
                className="h-12 w-12"
                aria-label="Back to landing page"
              >
                <ArrowLeftIcon className="w-10 hover:w-12 text-white" />
              </button>
              <h1 className="text-5xl text-white">{model['Model Name']}</h1>
            </div>

            {modelLogos?.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`${model['Model Name']} logo`}
                className="max-w-fit md:max-w-64 max-h-32 bg-white"
              />
            ))}
          </div>
          <p className="text-lg my-10 text-white">{model['Description']}</p>
          <TextArea tabs={tabs} />
        </main>
      )}
    </div>
  );
};

export default ModelPage;
