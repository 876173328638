import clsx from 'clsx';

const Button = ({ inverted, transparent, small, disabled, isLoading, ...props }) => {
  return (
    <div
      className={clsx(
        'relative font-medium',
        disabled || isLoading ? 'cursor-default' : 'cursor-pointer',
      )}
    >
      <button
        className={clsx(
          'flex gap-4 items-center justify-center text-lg shadow-sm px-6 py-4 min-w-40 cursor-pointer rounded-md transition-all border',
          small && 'px-3 py-3 ',
          inverted
            ? 'text-primary bg-white border-primary hover:bg-primary-50'
            : `text-white bg-primary ${
                transparent
                  ? 'border-white hover:bg-primary-dark'
                  : 'border-primary hover:bg-primary-400'
              }`,
          disabled || isLoading ? 'opacity-50' : 'opacity-100',
        )}
        {...props}
      ></button>
    </div>
  );
};

export default Button;
