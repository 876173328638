import { useEffect, useState } from 'react';
import clsx from 'clsx';

const PageSpacer = ({ children }) => (
  <div className="min-w-4 inline-block select-none">{children}</div>
);

const PaginationButton = ({ active, children, disabled, handleClick }) => (
  <button
    disabled={disabled}
    className={clsx(
      'rounded-sm cursor-pointer min-w-7 mx-2 hover:bg-primary-100 disabled:hidden',
      active ? 'bg-primary font-bold text-white' : 'text-primary',
    )}
    onClick={handleClick}
  >
    {children}
  </button>
);

/**
 * @param {
 *   pageNum: number,
 *   setPageNum: (page: number) => any,
 *   totalPages: number
 * }
 */
export const PageNavigation = ({ pageNum, setPageNum, totalPages }) => {
  const [mobileView, setMobileView] = useState(window.innerWidth < 850);

  window.addEventListener('resize', () => {
    setMobileView(window.innerWidth < 850);
  });

  useEffect(() => {
    setPageNum(1);
  }, [totalPages, setPageNum]);

  if (!totalPages) {
    return null;
  }
  // Buttons should start either 2 pages behing the current page or at the first page
  let pageButtonStart = Math.max(pageNum - 2, 1);
  // Buttons end 4 pages after the start (total of 5 page buttons)
  let pageButtonEnd = pageButtonStart + 4;

  // If there are not enough pages to show 5 pages, the last page shown should be the
  // the total number of pages
  if (pageButtonEnd > totalPages) {
    pageButtonEnd = totalPages;
    // And back-fill previous pages so that we still show 5 page buttons
    pageButtonStart = Math.max(1, pageButtonEnd - 4);
  }

  // Create an array of the pages we will show
  const pageButtons = Array(pageButtonEnd - pageButtonStart + 1)
    .fill()
    .map((_, i) => pageButtonStart + i);

  // If we can't see the first page, show it
  const showFirstPageButton = pageButtons[0] !== 1;
  // If we are a distance from the first page, add a spacer
  const showFirstPageSpacer = pageButtons[0] > 2;

  // If we can't see the last page, show it
  const showLastPageButton = pageButtons[pageButtons.length - 1] !== totalPages;
  // If we are a distance from the last page, add a spacer
  const showLastPageSpacer = pageButtons[pageButtons.length - 1] < totalPages - 1;

  return (
    <div className="flex justify-center py-4 bg-white rounded-b-md">
      {!mobileView && (
        <PaginationButton disabled={pageNum === 1} handleClick={() => setPageNum(pageNum - 1)}>
          {'< Previous'}
        </PaginationButton>
      )}
      {showFirstPageButton && (
        <PaginationButton handleClick={() => setPageNum(1)}>{1}</PaginationButton>
      )}
      {showFirstPageSpacer && <PageSpacer>...</PageSpacer>}
      {pageButtons.map(pageButtonNumber => (
        <PaginationButton
          active={pageButtonNumber === pageNum}
          key={pageButtonNumber}
          handleClick={() => setPageNum(pageButtonNumber)}
        >
          {pageButtonNumber}
        </PaginationButton>
      ))}
      {showLastPageSpacer && <PageSpacer>...</PageSpacer>}
      {showLastPageButton && (
        <PaginationButton handleClick={() => setPageNum(totalPages)}>{totalPages}</PaginationButton>
      )}
      {!mobileView && (
        <PaginationButton
          disabled={pageNum === totalPages}
          handleClick={() => setPageNum(pageNum + 1)}
        >
          {'Next >'}
        </PaginationButton>
      )}
    </div>
  );
};
