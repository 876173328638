import russellBlessingHeadshot from '../assets/headshots/Russell-Blessing.png';
import samuelBrodyHeadshot from '../assets/headshots/Samuel-Brody.png';
import wesleyHighfieldHeadshot from '../assets/headshots/Wes-Highfield.png';
import andrewJuanHeadshot from '../assets/headshots/Andrew-Juan.png';
import federicoAntoliniHeadshot from '../assets/headshots/Federico-Antolini.png';
import lauraStearnsHeadshot from '../assets/headshots/Laura-Stearns.png';
import lipaiHuangHeadshot from '../assets/headshots/Lipai-Huang.png';
import katharineTelekiHeadshot from '../assets/headshots/Katharine-Teleki.png';
import eshaClearfieldHeadshot from '../assets/headshots/Esha-Clearfield.png';
import jeffReichmanHeadshot from '../assets/headshots/Jeff-Reichman.png';
import gerardOtaloraHeadshot from '../assets/headshots/Gerard-Otalora.png';
import antoniaSebastianHeadshot from '../assets/headshots/Antonia-Sebastian.png';
import jackBaerHeadshot from '../assets/headshots/Jack-Baer.png';
import laurenGrimleyHeadshot from '../assets/headshots/Lauren-Grimley.png';
import keriStephensHeadshot from '../assets/headshots/Keri-Stephens.png';
import matthewMcGloneHeadshot from '../assets/headshots/Matthew-McGlone.png';
import samantaVarelaHeadshot from '../assets/headshots/Samanta-Varela.png';

const modelImages = require.context('../assets/gallery/', true);
const hecRas2DImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '1')
  .map(img => modelImages(img));
const hecRas1DImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '2')
  .map(img => modelImages(img));
const hecHmsImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '3')
  .map(img => modelImages(img));
const gsshaImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '4')
  .map(img => modelImages(img));
const mikePlusImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '5')
  .map(img => modelImages(img));
const mikeHDImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '6')
  .map(img => modelImages(img));
const fldWaveImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '8')
  .map(img => modelImages(img));
const pondpackImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '9')
  .map(img => modelImages(img));
const swmmImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '10')
  .map(img => modelImages(img));
const hspfImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '14')
  .map(img => modelImages(img));
const prmsImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '15')
  .map(img => modelImages(img));
const vicImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '16')
  .map(img => modelImages(img));
const swatImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '17')
  .map(img => modelImages(img));
const lisfloodImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '18')
  .map(img => modelImages(img));
const lisfloodFPImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '19')
  .map(img => modelImages(img));
const wrfHydroImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '20')
  .map(img => modelImages(img));
const adcircImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '23')
  .map(img => modelImages(img));
const bouss2DImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '24')
  .map(img => modelImages(img));
const cshoreImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '25')
  .map(img => modelImages(img));
const mike21Images = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '26')
  .map(img => modelImages(img));
const stwaveImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '28')
  .map(img => modelImages(img));
const whafisImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '29')
  .map(img => modelImages(img));
const delft3DImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '30')
  .map(img => modelImages(img));
const tuflowImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '31')
  .map(img => modelImages(img));
const sfincsImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '32')
  .map(img => modelImages(img));
const xbeachImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '33')
  .map(img => modelImages(img));
const vfloImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '34')
  .map(img => modelImages(img));
const telemacImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '35')
  .map(img => modelImages(img));
const sloshImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '36')
  .map(img => modelImages(img));
const schismImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '37')
  .map(img => modelImages(img));
const parflowImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '38')
  .map(img => modelImages(img));
const funwaveImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '39')
  .map(img => modelImages(img));
const fvcomImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '40')
  .map(img => modelImages(img));
const handImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '41')
  .map(img => modelImages(img));
const riverwareImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '43')
  .map(img => modelImages(img));
const adHImages = modelImages
  .keys()
  .filter(img => img.split('/')[1] === '44')
  .map(img => modelImages(img));

// Session expiration time (for localStorage)
export const SESSION_EXP_STORAGE_KEY = 'jafebp_session_expiration_time';

// Model images with captions and logos
export const MODEL_IMAGES = [
  {
    id: '1',
    images: [
      {
        index: 0,
        title: 'HEC-RAS 2D profile line',
        caption:
          'User-defined tracement of a profile line to assess simulation results at the desired scale (Source: HEC-RAS 2D).',
        image: hecRas2DImages[0],
      },
      {
        index: 1,
        title: 'HEC-RAS 2D profile line wse',
        caption:
          'Terrain elevation and water surface elevation (WSE) plotted along a cross-section profile line. (source: HEC-RAS 2D).',
        image: hecRas2DImages[1],
      },
      {
        index: 2,
        title: 'HEC-RAS 2D cross sections line flow time series',
        caption:
          'Discharge time-series (hydrograph) for a channel cross-section (source: HEC-RAS 2D).',
        image: hecRas2DImages[2],
      },
      {
        index: 3,
        title: 'HEC-RAS 2D depth plot query',
        caption: '2D plot of flood depths results (source: HEC-RAS 2D).',
        image: hecRas2DImages[3],
      },
      {
        index: 4,
        title: 'HEC-RAS 2D color-based velocity plot',
        caption:
          '2D color-based plot of maximum flow velocities in a channel and floodplain. Blue indicates low velocity, brown corresponds to highest velocities. In HEC-RAS 2D interface, it is possible to query velocity value at any location (source: HEC-RAS 2D).',
        image: hecRas2DImages[4],
      },
      {
        index: 5,
        title: 'HEC-RAS 2D particle tracing velocity',
        caption: '2D particle-tracing plot of maximum flow velocities (source: HEC-RAS 2D).',
        image: hecRas2DImages[5],
      },
      {
        index: 6,
        title: 'HEC-RAS 2D 3D perspective plot',
        caption: 'Alternative 3D perspective plot of flood depth results (source: HEC-RAS 2D).',
        image: hecRas2DImages[6],
      },
    ],
  },
  {
    id: '2',
    images: [
      {
        index: 0,
        title: 'HEC-RAS 1D cross section plot',
        caption: 'Cross-section water surface elevation plot (source: HEC-RAS 1D).',
        image: hecRas1DImages[0],
      },
      {
        index: 1,
        title: 'HEC-RAS 1D rating curve plot',
        caption: 'Rating curve for a channel cross-section (source: HEC-RAS 1D).',
        image: hecRas1DImages[1],
      },
      {
        index: 2,
        title: 'HEC-RAS 1D velocity distribution plot',
        caption: 'Velocity distribution in a channel cross-section (source: HEC-RAS 1D).',
        image: hecRas1DImages[2],
      },
      {
        index: 3,
        title: 'HEC-RAS 1D profile plot outlet to upstream',
        caption:
          'Water surface elevation plot along a longitudinal section from source (right) down to outlet (left) (source: HEC-RAS 1D).',
        image: hecRas1DImages[3],
      },
      {
        index: 4,
        title: 'HEC-RAS 1D stage and flow hydrograph plot',
        caption: 'Stage and flow hydrograph (source: HEC-RAS 1D).',
        image: hecRas1DImages[4],
      },
      {
        index: 5,
        title: 'HEC-RAS 1D breach hydrograph plot',
        caption:
          'Stage hydrograph and velocity profile for a dam breach simulation (source: HEC-RAS 1D).',
        image: hecRas1DImages[5],
      },
    ],
  },
  {
    id: '3',
    images: [
      {
        index: 0,
        title: 'HEC-HMS rain streamflow hydrograph',
        caption: 'Time series of precipitation and observed flow (source: HEC-HMS).',
        image: hecHmsImages[0],
      },
      {
        index: 1,
        title: 'HEC-HMS time series',
        caption: 'Time series of input and output hydrologic variables (source: HEC-HMS).',
        image: hecHmsImages[1],
      },
    ],
  },
  {
    id: '4',
    images: [
      {
        index: 0,
        title: 'GSSHA hydrograph',
        caption: 'Discharge hydrograph (source: Fattahi et al., 2023).',
        image: gsshaImages[1],
      },
      {
        index: 1,
        title: 'GSSHA hydrograph by mesh',
        caption: 'Discharge hydrographs by mesh resolution (source: Fattahi et al., 2023).',
        image: gsshaImages[2],
      },
      {
        index: 2,
        title: 'GSSHA flood peack total rainfall response time',
        caption:
          'Violin plots of (a) normalized flood peak and normalized total rainfall and (b) response time from 10- to 200-year rainfall return periods. (The red dot indicates the mean value. The dashed line in the middle indicates the median value. Upper and lower dashed lines indicate the 75th and 25th quantiles, respectively.) The rainfall return periods are calculated with respect to the average rainfall rate over the entire watershed (source: Zhou et al., 2021).',
        image: gsshaImages[3],
      },
      {
        index: 3,
        title: 'GSSHA hydrograph simulations',
        caption:
          'Time series of simulated hydrographs for Franklintown based on the 3 h design storms from 10- to 200-year return periods with spatially uniform (blue) and spatially distributed (red) rainfall. The grey bar indicates the median value of the basin-averaged rainfall rate (source: Zhou et al., 2021).',
        image: gsshaImages[4],
      },
      {
        index: 4,
        title: 'GSSHA max flood depth hurricane maria rain only vs storm surge',
        caption:
          'Maximum flooding depth using the observed USGS stream discharge data for Hurricane María as an inflow boundary condition and WRF rainfall. (a) Without incorporating storm surge; (b) including the storm surge (source: Mejia Manrique et al., 2021). ',
        image: gsshaImages[5],
      },
    ],
    logos: [gsshaImages[0]],
  },
  {
    id: '5',
    images: [
      {
        index: 0,
        title: 'MIKE PLUS cross section plot 2D simulation',
        caption:
          'Cross section plot window showing results from a 2D overland simulation (source: MIKE+).',
        image: mikePlusImages[1],
      },
      {
        index: 1,
        title: 'MIKE PLUS flood depth',
        caption:
          'Comparison of original flood depth results (left) and created polygons with three depth categories (right) (source: MIKE+).',
        image: mikePlusImages[2],
      },
      {
        index: 2,
        title: 'MIKE PLUS longitudinal profile plot',
        caption:
          'Profile Plot form window showing an example longitudinal profile plot 2D with water level simulation results (blue line) (source: MIKE+).',
        image: mikePlusImages[3],
      },
    ],
    logos: [mikePlusImages[0]],
  },
  {
    id: '6',
    images: [
      {
        index: 0,
        title: 'MIKE 11 scenario hydrograph',
        caption:
          'Environmental Flow Requirement (EFR) and simulated discharge in the Strymonas River estuary for Scenario 1 (fulfillment of irrigation requirements) and Scenario 2 (fulfillment of lake’s ecosystem requirements) (source: Doulgeris et al., 2012).',
        image: mikeHDImages[0],
      },
    ],
  },
  {
    id: '8',
    images: [
      {
        index: 0,
        title: 'FLDWAV flood depth grid',
        caption: 'Maximum flood depths for Levee #1 failure scenarios (source: Kim et al., 2019).',
        image: fldWaveImages[1],
      },
    ],
    logos: [fldWaveImages[0]],
  },
  {
    id: '9',
    images: [
      {
        index: 0,
        title: 'PONDPACK pre vs post outflow',
        caption:
          'Hydrograph comparison among pre-development scenario (pink), post-development scenario without pond (blue) and post-development scenario witht pond in place (green) (source:  Bentley systems)',
        image: pondpackImages[0],
      },
    ],
  },
  {
    id: '10',
    images: [
      {
        index: 0,
        title: 'SWMM storm water calculator monthly rainfall',
        caption:
          'Climate change projections calculated with the National Stormwater Calculator (source: SWMM).',
        image: swmmImages[2],
      },
      {
        index: 1,
        title: 'SWMM storm water calculator rainfall scenarios',
        caption:
          'Rainfall/runoff statistics generated by SWMM on the National Stormwater Calculator (source: SWMM).',
        image: swmmImages[3],
      },
      {
        index: 2,
        title: 'SWMM hydrograph',
        caption:
          'Extracting RDII flow from a continuous flow monitor (source: Vallabhaneni et al., 2002).',
        image: swmmImages[4],
      },
      {
        index: 3,
        title: 'SWMM subcatchment runoff',
        caption: 'Subcatchment runoff volume and depth (source: SWMM).',
        image: swmmImages[5],
      },
      {
        index: 4,
        title: 'SWMM water elevation profile',
        caption: 'Water elevation longitudinal profile (source: SWMM).',
        image: swmmImages[6],
      },
    ],
    logos: [swmmImages[0], swmmImages[1]],
  },
  {
    id: '14',
    images: [
      {
        index: 0,
        title: 'HSPF streamflow exceedance probability',
        caption:
          'Comparison of hoimagey observed and simulated streamflow by HSPF and SWMM for calibration and validation periods (a) Calibration period for 2013 (b) Validation period for 2009–2011 (c) Observed and simulated data for December 2009 (d) Observed and simulated data for May 2011 (source: Yazdi et al., 2019).',
        image: hspfImages[0],
      },
      {
        index: 1,
        title: 'HSPF observed simulated streamflow',
        caption:
          'Comparison of flow duration curves of simulated streamflow by HSPF and SWMM and observed streamflow (source: Yazdi et al., 2019).',
        image: hspfImages[1],
      },
    ],
  },
  {
    id: '15',
    images: [
      {
        index: 0,
        title: 'PRMS streamflow calibration',
        caption:
          'Measured and simulated daily streamflow for the calibration and evaluation periods (XYZ, xyz_dist module; IDE, ide_dist module) (source: Markstrom et al., 2015, USGS).',
        image: prmsImages[0],
      },
      {
        index: 1,
        title: 'PRMS observed vs simulated streamflow',
        caption:
          'Observed and simulated streamflow and observed precipitation at the Cloudcroft raingage for prefire (2010) and postfire (2014) conditions, June through November, North Fork Eagle Creek subwatershed, New Mexico (source: Douglas-Mankin and Moeser, 2019, USGS).',
        image: prmsImages[1],
      },
      {
        index: 2,
        title: 'PRMS observed vs simulated water balance data',
        caption:
          'Observed versus simulated water balance data in the Hockanum River. a. Maximum daily temperature and PRMS’s simulated output; b. Minimum daily temperature and PRMS’s simulated output; c. Monthly precipitation and PRMS’s simulated output; d. Daily streamflow between USGS streamgage and PRMS’s simulated output (source: Tardif, 2018)',
        image: prmsImages[2],
      },
    ],
  },
  {
    id: '16',
    images: [
      {
        index: 0,
        title: 'VIC past and projected annual water yield and demand by basin',
        caption:
          'Past and projected annual water yield and demand by basin. (a) Water yield in past period (Bm3). (b) Percent change in water yield from past period to mid future period, mean of 14 futures. (c) Water demand in the past period (Mm3). (d) Percent change in water demand from past period to mid future period, mean of 14 futures. Time periods: past (1985–2010) and midfuture (2046–2070) (source: Brown et al., 2019)',
        image: vicImages[0],
      },
    ],
  },
  {
    id: '17',
    images: [
      {
        index: 0,
        title: 'SWAT peak flow baseline vs green infrastructure scenarios',
        caption:
          'Peak flow rate distribution for the Brentwood tributary, with and without LID controls (source: Glick et al., 2023)',
        image: swatImages[0],
      },
    ],
  },
  {
    id: '18',
    images: [
      {
        index: 0,
        title: 'LISFLOOD observed vs simulated discharge',
        caption:
          'Observed and simulated discharge at Dresden for calibration (top) and validation (bottom) period. Note that both model runs were preceded by a one‐year warm‐up period, which is not shown here. Inset bottom graph zooms in on the August 2002 flood (source: Van der Knijff et al., 2008).',
        image: lisfloodImages[0],
      },
    ],
  },
  {
    id: '19',
    images: [
      {
        index: 0,
        title: 'LISFLOOD-FP flood water source tracing',
        caption:
          'Model results showing the Carlisle, UK, flood extent on 10 January 2005, 06:00, close to the flood peak. Depths and fractions for four selected locations, marked A–D, are shown (source: Wilson and Coulthard, 2023).',
        image: lisfloodFPImages[0],
      },
      {
        index: 1,
        title: 'LISFLOOD-FP flood forecast',
        caption:
          'Six-hour ahead real-time flood forecasting figure for Sheepmount at 2 p.m., 7 January 2005. Included in the figure are present and previous observed levels (solid black line), previous 6-h forecasts plus 99% and 95% confidence intervals (black dots plus light and dark grey patch), the present time period (vertical line), warning threshold levels (horizontal lines), 1–6-h ahead level forecasts plus 99% and 95% confidence intervals (black dots with red outline plus light and dark reddish-grey patch) (source: Leedal et al., 2010).',
        image: lisfloodFPImages[1],
      },
      {
        index: 2,
        title: 'LISFLOOD-FP postproc prob inund maps',
        caption:
          'Probabilistic flood inundation maps generated by open-loop and Ensemble Kalman filter techniques to simulate the upper limb of the Hurricane Harvey flood hydrograph from 27 to 29 August (source: Jafarzadegan et al., 2021).',
        image: lisfloodFPImages[2],
      },
      {
        index: 3,
        title: 'LISFLOOD-FP urban flood map',
        caption:
          'Model and benchmark data produced by LISFLOOD-FP for Carlisle, UK, 2005 flooding (source: https://www.seamlesswave.com/Carlistle_flooding.html)',
        image: lisfloodFPImages[3],
      },
    ],
  },
  {
    id: '20',
    images: [
      {
        index: 0,
        title: 'WRF-HYDRO ensemble streamflow predictions',
        caption:
          'Ensemble streamflow predictions produced by National Water Model for a North Carolina watershed (source: NCAR)',
        image: wrfHydroImages[1],
      },

      {
        index: 1,
        title: 'WRF-HYDRO flow forecast vs freq exceedance',
        caption: 'Flood forecast compared to flood frequency exceedance levels (source: NCAR) ',
        image: wrfHydroImages[2],
      },

      {
        index: 2,
        title: 'WRF-HYDRO surface runoff map by development imperviousness scenario',
        caption:
          'Accumulated surface runoff in the Los Angeles metropolitan area over the December 2010 precipitation event for (a) the pre-urban development scenario without impervious surfaces and (b) the post-urban development scenario under present imperviousness conditions (source: Pasquier et al., 2022).',
        image: wrfHydroImages[3],
      },

      {
        index: 3,
        title: 'WRF-HYDRO soil moisture map by development imperviousness scenario',
        caption:
          'Soil moisture storage (2 m soil column) in the Los Angeles metropolitan area over the December 2010 precipitation event for (a) the pre-urban development scenario without impervious surfaces and (b) the post-urban development scenario under present imperviousness conditions (source: Pasquier et al., 2022).',
        image: wrfHydroImages[4],
      },

      {
        index: 4,
        title: 'WRF-HYDRO infiltration by rain amount',
        caption:
          'The range, interquartile range, and median value of (A) total infiltration, (B) local infiltration, and (C) re-infiltration amount for varying precipitation intensities (source: Zhang et al., 2020).',
        image: wrfHydroImages[5],
      },

      {
        index: 5,
        title: 'WRF-HYDRO streamflow calibration',
        caption:
          'Calibration results of 2015 May Event in North Central Texas for 18 gauges (source: Zhang et al., 2020).',
        image: wrfHydroImages[6],
      },

      {
        index: 6,
        title: 'WRF-HYDRO probabilistic pluvial flooding',
        caption:
          'Experimental cumulative ponded water estimate in Harris County, TX, during Hurricane Harvey. Water values less than one-half-foot were excluded due to model uncertainty (source: NCAR). ',
        image: wrfHydroImages[7],
      },
    ],
    logos: [wrfHydroImages[0]],
  },
  {
    id: '23',
    images: [
      {
        index: 0,
        title: 'ADCIRC katrina 2015 max elevation',
        caption:
          'Zoomed-in maximum water elevations from 2015 simulation of Hurricane Katrina (source: ADCIRC).',
        image: adcircImages[0],
      },
      {
        index: 1,
        title: 'ADCIRC katrina 2015 maxwvel close',
        caption:
          'Zoomed-in maximum wind speeds from 2015 simulation of Hurricane Katrina (source: ADCIRC).',
        image: adcircImages[1],
      },
      {
        index: 2,
        title: 'ADCIRC hurricane max water level map',
        caption:
          'Maximum elevations obtained from ADCIRC validated for Hurricane Ike at the original landfall (OL) location (source: Sebastian et al., 2014).',
        image: adcircImages[2],
      },
      {
        index: 3,
        title: 'ADCIRC hurricane water level timeseries',
        caption:
          'Hurricane Ike storm surge (m) at 14 points indicative of surge behavior. Time is in GMT and landfall on 9/13/08 at 0600 GMT is marked as “OL”. a) Location of points and hydrograph output for the coast; b) location of points and hydrograph output for the lower bay; c) location of points and hydrograph output for the upper bay; d) location of points and hydrograph output for the track (source: Sebastian et al., 2014).',
        image: adcircImages[3],
      },
      {
        index: 4,
        title: 'ADCIRC observed vs modeled storm tide',
        caption:
          'Comparison of modeled tide against the observed tide at Paradeep (20.24°N, 86.64°E), (b) Comparison of model simulated storm tides with observations for Phailin cyclone. Black dot shows the cyclone landfall time (source: Pandey et al., 2021).',
        image: adcircImages[4],
      },
      {
        index: 5,
        title: 'ADCIRC observed vs simulated water level',
        caption:
          'Time series of the observed (blue) versus simulated (red) water levels (storm tide) at (a) Battery and (b) Bergen Point during Hurricane Sandy (source: Yin et al., 2016).',
        image: adcircImages[5],
      },
      {
        index: 6,
        title: 'ADCIRC max inundation map',
        caption:
          'The maximum inundation measured by (a) FEMA, (b) ADCIRC, and (c) coupled ADCIRC-FloodMap at street level (lower Manhattan) during Hurricane Sandy (source: Yin et al., 2016).',
        image: adcircImages[6],
      },
    ],
  },
  {
    id: '24',
    images: [
      {
        index: 0,
        title: 'BOUSS 2D currents and tides',
        caption:
          'Spatial distribution in the W–SW conditions of the BOUSS-2D currents (left panels) and schematic interpretation (right panels) for the Chiringuito beach: (a) -0.5 m MSL tide; (b) 0 m MSL tide; (c) 0.5 m MSL tide; (d) 1 m MSL tide; and (e) 1.5 m MSL tide (source: Horta et al., 2018).',
        image: bouss2DImages[0],
      },
    ],
  },
  {
    id: '25',
    images: [
      {
        index: 0,
        title: 'CSHORE simulated bed level evolution compared to pre and post storm bed level',
        caption:
          "CSHORE simulated bed level evolution compared to pre- and post-storm (2008 Hurricane Ike) bed level extracted from LiDAR data at Sections A (the east end of Follet's Island, TX) and Section D (near the center of Follet's Island) (source: Harter and Figlus, 2017).",
        image: cshoreImages[0],
      },
      {
        index: 1,
        title: 'CSHORE wave height vs vegetation height and other params',
        caption:
          'Comparisons of profiles of wave heights (Hrms) through the vegetation patch (x = 0.0~9.8m) (source: Ding et al., 2022).',
        image: cshoreImages[1],
      },
    ],
  },
  {
    id: '26',
    images: [
      {
        index: 0,
        title: 'MIKE 21 instantaneous surface elevation in harbour',
        caption:
          'Instantaneous surface elevation from a 2D Boussinesq Waves model in Rønne harbour, Denmark (source: MIKE 21 BW manual).',
        image: mike21Images[0],
      },
      {
        index: 1,
        title: 'MIKE 21 wave disturbance in harbour',
        caption:
          'Wave disturbance from a 2D Boussinesq Waves model in Rønne harbour, Denmark (source: MIKE 21 BW manual).',
        image: mike21Images[1],
      },
      {
        index: 2,
        title: 'MIKE 21 simulated instantaneous surface elevation 3D',
        caption:
          '3D picture of the simulated instantaneous surface elevation in Rønne harbour, Denmark (source: MIKE 21 BW manual).',
        image: mike21Images[2],
      },
      {
        index: 3,
        title: 'MIKE 21 instantaneous wave height',
        caption:
          'Map of wave height simulated by MIKE 21 BW in Torsminde Harbour, Denmark. (source: MIKE 21 BW manual).',
        image: mike21Images[3],
      },
    ],
  },
  {
    id: '28',
    images: [
      {
        index: 0,
        title: 'STWAVE wave height by storm scenario',
        caption:
          'Example of STWAVE results (wave direction and height) for multiple storm scenarios (source: Horta et al., 2018)',
        image: stwaveImages[0],
      },
      {
        index: 1,
        title:
          'STWAVE time series wave height peak, period mean period, mean wave direction for Sandy',
        caption:
          'Model results (wave height, peak period, mean period, and mean wave direction) versus measurements at National Data Buoy Center 44065 for Hurricane Sandy (source: Bryant and Jensen, 2017).',
        image: stwaveImages[1],
      },
      {
        index: 2,
        title: 'STWAVE time series wave height superstorm Sandy',
        caption:
          'Wave height for Hurricane Sandy: (a) First Landing, VA; (b) Bethany Beach, DE; (c) Ocean City, MD (source: Bryant and Jensen, 2017).',
        image: stwaveImages[2],
      },
      {
        index: 3,
        title: 'STWAVE total water depth computer at residential scale 100 year storm',
        caption:
          'Total water depth computed at the residential scale (d; vertical hot color scale in meter) around each exposed structure, based on FUNWAVE (left column) and STWAVE simulations (right column) of the 100-year SDS in Site: (a) 1, and (b) 2; horizontal green/blue scale in meter). Axes are in UTM coordinates (zone 19N) (source: Grilli et al., 2020).',
        image: stwaveImages[3],
      },
    ],
  },
  {
    id: '29',
    images: [
      {
        index: 0,
        title: 'WHAFIS daily wave height interpolated between transects by sediment scenario',
        caption:
          'Baseline daily (high tide) wave height (in feet) and projected change in daily wave height through time under the “high” sediment scenario (source: Hayden et al., 2019).',
        image: whafisImages[0],
      },
      {
        index: 1,
        title:
          'WHAFIS annual storm wave height interpolated between transects by sediment scenario',
        caption:
          'Baseline annual storm wave height and projected change in annual storm wave height through time under the “high” sediment scenario. Note the scale range differs from Figure 7. See Appendix Figure B-2 for “low” sediment scenario comparison (source: Hayden et al., 2019).',
        image: whafisImages[1],
      },
      {
        index: 2,
        title: 'WHAFIS wave energy coastal levee',
        caption:
          'Metric of wave energy at the first line of defense (typically a levee) through time, under the high sediment scenario and an annual storm condition. The projected future year is coded by color. Larger circles indicate more wave energy at the first line of defense, thus more potential for erosion and increased maintenance cost of shoreline protection structures (source: Hayden et al., 2019).',
        image: whafisImages[2],
      },
    ],
  },
  {
    id: '30',
    images: [
      {
        index: 0,
        title: 'DELFT 3D observed vs modeled stage hydrograph USGS stations hurricane Harvey',
        caption:
          'Comparison of hydrographs between observed (black) and modeled (red) at USGS stations for 2017 Hurricane Harvey (source: Lee et al., 2024).',
        image: delft3DImages[0],
      },
      {
        index: 1,
        title: 'DELFT 3D max flood depth by flood driver hurricane Harvey',
        caption:
          'Maximum flooding depth by the combinations of each driver: (left-top) winds-driven surge, (right-top) winds + rains, (left-bottom) winds + rivers, (right-bottom) winds + rains + rivers all combined (source: Lee et al., 2024).',
        image: delft3DImages[1],
      },
    ],
  },
  {
    id: '31',
    images: [
      {
        index: 0,
        title: 'TUFLOW superstorm Sandy observed vs modeled flood depth',
        caption:
          'Comparison of a) observed, b) model-simulated inundation depth and c) bias for Hurricane Sandy using SMS-TUFLOW. The solid line outlines the administrative boundary of Brick Township, NJ. d) summary of performance metrics comparing the model-simulated results generated by SMS-TUFLOW with observed FEMA MOTF inundation product. e) scatter plot showing observed vs. model-simulated flood depth for 60 random points sampled within the boundary of the study area. f) spatial distribution of model performance metrics compared with FEMA MOTF reanalysis product (source: Fahad et al., 2020).',
        image: tuflowImages[1],
      },
      {
        index: 1,
        title: 'TUFLOW velocity contour map',
        caption:
          '2D Model velocity contours in Widemere Road reach of Prospect Creek (Fairfield, Australia). (source: Barnard et al., 2007)',
        image: tuflowImages[2],
      },
    ],
    logos: [tuflowImages[0]],
  },
  {
    id: '32',
    images: [
      {
        index: 0,
        title: 'SFINCS compound flood extent by driver',
        caption:
          'Compound flood dynamics during Tropical Cyclone Idai (a, c) and Tropical Cyclone Eloise (b, d) illustrated by the difference between water levels from the compound flood scenario and the maximum of all single-driver scenarios (a, b) and the main flood driver based on the single-driver scenario with the maximum water level (c, d). The main driver is indicated with light colors where the water level results from a single flood driver and dark colors where it results from more than one flood driver, also referred to as the transition zone. (source: Eilander et al., 2023).',
        image: sfincsImages[0],
      },
      {
        index: 1,
        title: 'SFINCS XBEACH watermark vs model 2D bathymetry',
        caption:
          'Runup extent as observed (purple), computed with XB-2D (red) and SFINCS (blue). Colors depict the bed level in meter relative to NAVD88. Figure uses a cross-shore- and alongshore-distance coordinate system (source: Nederhoff et al., 2024).',
        image: sfincsImages[1],
      },
      {
        index: 2,
        title: 'SFINCS flood extent vs sea level rise',
        caption:
          'Flood extent for Whatcom County as function of sea level (SLR) for different return periods. Shading represents the 95% CI interval of the flood simulation (+/− 50 cm offshore water level). Uncertainty is based on errors in the offshore water level, wave height, and digital elevation model (DEM) (source: Nederhoff et al., 2024).',
        image: sfincsImages[2],
      },
    ],
  },
  {
    id: '33',
    images: [
      {
        index: 0,
        title: 'XBEACH hurricane Ike impact on seashore and inundation',
        caption:
          'Snapshot of water surface (long waves only) and bed level during the collision regime (top-left), during the overtopping regime (top-right), during the inundation regime (bottom-left), and during storm surge ebb (bottom-right) (source: Harter and Figlus, 2017)',
        image: xbeachImages[0],
      },
      {
        index: 1,
        title: 'XBEACH simulated bed level evolution compare to pre and post storm bed level',
        caption:
          "XBeach simulated bed level evolution compared to pre- and post-storm (2008 Hurricane Ike) bed level extracted from LiDAR data at Sections A (the east end of Follet's Island) and Section D (near the center of Follet's Island) (source: Harter and Figlus, 2017).",
        image: xbeachImages[1],
      },
      {
        index: 2,
        title: 'XBEACH time series of significant wave height at hurricane Michael',
        caption:
          'Comparison of time series of significant wave height at USGS gauge near Mexico Beach, FL, for 2018 Hurricane Michael (source: Ma et al., 2024).',
        image: xbeachImages[2],
      },
      {
        index: 3,
        title: 'XBEACH observed vs modeled bed elevations along cross section',
        caption:
          "Comparison of model simulated bed elevations with observations along cross-section A and B. XBNH model is more accurate with Excellent performance (BBS> 0.85), while the XBSB's performance is Good (BBS 0.6–0.8) (source: Ma et al., 2024).",
        image: xbeachImages[3],
      },
    ],
  },
  {
    id: '34',
    images: [
      {
        index: 0,
        title: 'Vflo modeled normalized streamflow LULC scenarios',
        caption:
          'Modeled normalized streamflow (cms/km2) for Brays Bayou (Houston, TX) under three LULC scenarios (source: Juan et al., 2020).',
        image: vfloImages[0],
      },
    ],
  },
  {
    id: '35',
    images: [
      {
        index: 0,
        title: 'TELEMAC velocity distributions and streamlines',
        caption:
          'Velocity distribution and streamlines in the flood tide and ebb tide periods (source: Lieou et al., 2024).',
        image: telemacImages[0],
      },
      {
        index: 1,
        title: 'TELEMAC observed vs modelled wave height',
        caption:
          'Observed and modelled significant wave height during the winter months of 2014–2015 (source: Cousineau and Murphy, 2022).',
        image: telemacImages[1],
      },
    ],
  },
  {
    id: '36',
    images: [
      {
        index: 0,
        title: 'SLOSH model domain grid',
        caption:
          'Slosh model domain and grid (i.e., basin) for Korea Peninsular (source: Kim, 2020).',
        image: sloshImages[0],
      },
      {
        index: 1,
        title: 'SLOSH max of max surge height',
        caption:
          'Maximum of Maximum surge height for the case of 60 storm tracks of 50-year return frequency strength with the sea-level rise of 98 cm above current sea level (source: Kim, 2020).',
        image: sloshImages[1],
      },
      {
        index: 2,
        title: 'SLOSH Sandy forecast surge fide MEOW',
        caption:
          'Summary plot of the ensemble results for the simulations of Hurricane Sandy (2012) valid 48 h prior to landfall: (a) for surge and (b) for surge + tides. Ensemble maximum envelope of water for (c) surge and (d) surge + tides 48 h prior to landfall with maximum total water levels of 4.94 m and 5.46 m, respectively (relative to the NAVD88 vertical datum) (source: Forbes et al., 2014).',
        image: sloshImages[2],
      },
      {
        index: 3,
        title: 'SLOSH observed vs modeled max water level time of arrival',
        caption:
          'NOAA stations vs. SLOSH maximum water levels for (a) surge and (b) surge-plus-tides and the time-of-arrival of the peak water levels for (c) surge and (d) surge-plus-tides, for 2012 Hurricane Sandy (source: Forbes et al., 2014).',
        image: sloshImages[3],
      },
    ],
  },
  {
    id: '37',
    images: [
      {
        index: 0,
        title: 'SCHISM compund flooding streamflow hurricane Irene',
        caption:
          'Comparisons of a) streamflow and b) river stage at the USGS station Trenton, NJ. c), d) Flow errors and cumulative errors from SCHISM and NWM respectively (source: Zhang et al., 2020).',
        image: schismImages[1],
      },
      {
        index: 1,
        title: 'SCHISM max flood depth baseline vs hurricane Irene',
        caption:
          'Comparison of the maximum inundation depths between two grid resolutions: a) baseline, 150-m resolution in the watershed above MSL; b) sensitivity run with a 300-m resolution in the watershed above MSL (source: Zhang et al., 2020).',
        image: schismImages[2],
      },
    ],
    logos: [schismImages[0]],
  },
  {
    id: '38',
    images: [
      {
        index: 0,
        title: 'PARFLOW subsurface storage',
        caption:
          'Heterogeneous Differences in Subsurface Storage between Two One-Year Simulations under Identical Meteorological Forcing. Red areas had smaller subsurface storage in 2030 as compared to 2007, whereas blue areas had greater subsurface storage in 2030 compared to 2007 (source: Bhaskar et al., 2016).',
        image: parflowImages[0],
      },
      {
        index: 1,
        title: 'PARFLOW groundwater flux storage',
        caption:
          'Daily time series of bulk fluxes to/from the simulated Central Valley, CA aquifer system. Positive values indicate a net flow into the aquifer. Negative values indicate net flow out of the aquifer. The balance of inflows and outflows is shown as the change in storage (black line) (source: Gilbert and Maxwell, 2017).',
        image: parflowImages[1],
      },
    ],
  },
  {
    id: '39',
    images: [
      {
        index: 0,
        title: 'FUNWAVE flood area 100 year storm',
        caption:
          'Area inundated by the 100-year SDS. (a, b) Topographical elevation (color scale in meter; NAVD88) in: (a) FUNWAVE, and (b) STWAVE simulations. Envelope of maximum: (c) Total Water Level (NAVD88) and (d) Total Water Depth (color scale in meter), in FUNWAVE simulations (source: Grilli et al., 2020)',
        image: funwaveImages[0],
      },
      {
        index: 1,
        title: 'FUNWAVE total water level detail',
        caption:
          'Zoom-in of Total Water Level on Coast Guard House area (source: Grilli et al., 2020).',
        image: funwaveImages[1],
      },
      {
        index: 2,
        title: 'FUNWAVE total water depth computed at residential scale 100 year storm',
        caption:
          'Total water depth computed at the residential scale (d; vertical hot color scale in meter) around each exposed structure, based on FUNWAVE (left column) and STWAVE simulations (right column) of the 100-year SDS in Site: (a) 1, and (b) 2; horizontal green/blue scale in meter). Axes are in UTM coordinates (zone 19N) (source: Grilli et al., 2020).',
        image: funwaveImages[2],
      },
    ],
  },
  {
    id: '40',
    images: [
      {
        index: 0,
        title: 'FVCOM surface wave height and directions map',
        caption:
          'Maps of surface wave height (m) and direction (arrows) at 08 GMT 27 December. Left panels: CR-grid; right panels: HR-grid. Top row: wave field without wave-current interaction; bottom row: wave field with wave-current interaction (source: Beardsley et al., 2013).',
        image: fvcomImages[0],
      },
      {
        index: 1,
        title: 'FVCOM depth-averaged current map sequence',
        caption:
          'Sequence of maps of depth-averaged current in and near Scituate Harbor entrance for the HR-grid case with wave-current interaction at 00, 03, 06, 09, 12 and 15 GMT 27 December. The model-simulated first high tide on 27 December occurred at 7:20 GMT (source: Beardsley et al., 2013).',
        image: fvcomImages[1],
      },
      {
        index: 2,
        title: 'FVCOM sea level and tidal current velocities',
        caption:
          'Distributions of the model-predicted sea level and vertically averaged tidal current vectors at the maximum flood (a), flood to ebb (high water) (b), the maximum ebb (c), and ebb to flood (low water) (d) (source: Chen et al., 2022).',
        image: fvcomImages[2],
      },
      {
        index: 3,
        title: 'FVCOM flooded areas during tidal event',
        caption:
          'Simulated flooded areas at 9:38 AM (a), the lowest water level, 10:13 AM (b), 11:12 AM (c), 13:04 PM (d), 14:08 PM (e), and 15:04 (f), the highest water level, 9 November 2001, in the Okatee tidal creek, SC (source: Chen et al., 2022).',
        image: fvcomImages[3],
      },
    ],
  },
  {
    id: '41',
    images: [
      {
        index: 0,
        title: 'HAND hand raster Houston',
        caption: 'HAND raster of greater Houston area (source: Zheng, 2016).',
        image: handImages[0],
      },
      {
        index: 1,
        title: 'HAND hand detail',
        caption:
          'Closer look at the closeness of fit between NHDPlus flowlines (red) and GeoNet extracted skeleton with HAND (light blue) (source: Zheng, 2016).',
        image: handImages[1],
      },
      {
        index: 2,
        title: 'HAND water depth grids at increasing heights',
        caption:
          'Water depth grids at different water levels: (a) 0 m, (b) 3.05 m, (c) 6.10 m, and (d) 9.14 m (source: Zheng et al., 2018).',
        image: handImages[2],
      },
    ],
  },
  {
    id: '43',
    images: [
      {
        index: 0,
        title: 'Riverware discharge timeseries',
        caption: 'Discharge time series (source: Riverware).',
        image: riverwareImages[1],
      },
      {
        index: 1,
        title: 'Riverware water elevation timeseries',
        caption: 'Water elevation time series (source: Riverware).',
        image: riverwareImages[2],
      },
    ],
    logos: [riverwareImages[0]],
  },
  {
    id: '44',
    images: [
      {
        index: 0,
        title: 'AdH max water elevation Houston different flood drivers',
        caption:
          'Longitudinal profile of maximum water surface elevations for the monolithic simulation, simulations with isolated flood drivers, and the superposition of the storm surge and rainfall-runoff only simulations along the Houston Ship channel through Galveston Bay and (a) Buffalo Bayou; and (b) the San Jacinto River. The shaded region indicates the transition zone (source: Stephens et al., 2022).',
        image: adHImages[0],
      },
      {
        index: 1,
        title: 'AdH compound flooding velocity',
        caption:
          'Velocity magnitudes on August 30, 2017 0:00 UTC at a constriction point near the confluence of Buffalo Bayou and the San Jacinto River (source: Stephens et al., 2022).',
        image: adHImages[1],
      },
    ],
  },
];

// Team members
export const TEAM_MEMBERS = [
  {
    name: 'Russell Blessing, PI',
    title: 'Research Scientist',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: russellBlessingHeadshot,
    email: 'rblessing@tamu.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2021/01/Russell-Blessing_CV.pdf',
  },
  {
    name: 'Samuel Brody, co-PI',
    title: 'Director',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: samuelBrodyHeadshot,
    email: 'sbrody@tamu.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2021/01/Sam-Brody_-CV.pdf',
  },
  {
    name: 'Wesley Highfield, co-PI',
    title: 'Senior Research Scientist',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: wesleyHighfieldHeadshot,
    email: 'highfiew@tamug.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2021/01/Wesley-Highfield_CV.pdf',
  },
  {
    name: 'Andrew Juan',
    title: 'Associate Research Scientist',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: andrewJuanHeadshot,
    email: 'Andrew.juan@tamu.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2023/01/Andrew-CV.pdf',
  },
  {
    name: 'Federico Antolini',
    title: 'Assistant Research Scientist',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: federicoAntoliniHeadshot,
    email: 'antolini@tamug.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/07/cv_Federico_Antolini_jul2024.pdf',
  },
  {
    name: 'Laura Stearns',
    title: 'Research Specialist II',
    affiliation: 'Institute for a Disaster Resilient Texas',
    headshot: lauraStearnsHeadshot,
    email: 'lstearns@tamug.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2021/01/Laura-Stearns_CV.pdf',
  },
  {
    name: 'Lipai (Pie) Huang',
    title: 'PhD Student',
    affiliation: 'UrbanResilience.AI',
    headshot: lipaiHuangHeadshot,
    email: 'lipai.huang@tamu.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/Lipai-Bio.pdf',
  },
  {
    name: 'Katharine Teleki',
    title: 'Project Manager',
    affiliation: 'Teleki Consulting, LLC',
    headshot: katharineTelekiHeadshot,
    email: 'katharine@telekiconsulting.com',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/Teleki_Katharine_Resume_1_2024.pdf',
  },
  {
    name: 'Esha Clearfield',
    title: 'Project Manager',
    affiliation: 'Teleki Consulting, LLC',
    headshot: eshaClearfieldHeadshot,
    email: 'esha@telekiconsulting.com',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/Esha-Clearfield_Bio-1.pdf',
  },
  {
    name: 'Jeff Reichman',
    title: 'Principal',
    affiliation: 'January Advisors',
    headshot: jeffReichmanHeadshot,
    email: 'jeff@januaryadvisors.com',
  },
  {
    name: 'Gerard Otalora',
    title: 'Web Developer',
    affiliation: 'January Advisors',
    headshot: gerardOtaloraHeadshot,
    email: 'gerard@januaryadvisors.com',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/09/Gerard-Otalora-Resume.pdf',
  },
  {
    name: 'Antonia Sebastian',
    title: 'Assistant Professor',
    affiliation: 'The University of North Carolina at Chapel Hill',
    headshot: antoniaSebastianHeadshot,
    email: 'a.sebastian@unc.edu',
    cv: 'https://sph.unc.edu/wp-content/uploads/sites/112/2023/08/CV_Sebastian_072023.pdf',
  },
  {
    name: 'Jack Baer',
    title: 'Graduate Student',
    affiliation: 'The University of North Carolina at Chapel Hill',
    headshot: jackBaerHeadshot,
    email: 'jabaer@unc.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/BaerCV_SeaGrant_v2.pdf',
  },
  {
    name: 'Lauren Elise Grimley',
    title: 'Graduate Student',
    affiliation: 'The University of North Carolina at Chapel Hill',
    headshot: laurenGrimleyHeadshot,
    email: 'lauren.grimley@unc.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/Lauren_Grimley_CV.pdf',
  },
  {
    name: 'Keri Stephens',
    title: 'Professor',
    affiliation: 'The University of Texas at Austin',
    headshot: keriStephensHeadshot,
    email: 'keri.stephens@austin.utexas.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/06/Keri-K.-Stephens-CV.pdf',
  },
  {
    name: 'Matthew McGlone',
    title: 'Professor',
    affiliation: 'The University of Texas at Austin',
    headshot: matthewMcGloneHeadshot,
    email: 'matthew_macglone@austin.utexas.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/08/McGlone-MS-CV-6.3.24.pdf',
  },
  {
    name: 'Samanta Varela Castro',
    title: 'Postdoctoral Fellow',
    affiliation: 'The University of Texas at Austin',
    headshot: samantaVarelaHeadshot,
    email: 'samanta.varela@austin.utexas.edu',
    cv: 'https://idrt.tamug.edu/wp-content/uploads/2024/06/Samanta-Bio.pdf',
  },
];
