import clsx from 'clsx';
import ContributorCard from './general/ContributorCard';
import { TEAM_MEMBERS } from '../constants/general';

const About = () => {
  const contentStyle = 'text-lg my-6 md:text-justify';
  return (
    <div className="w-full min-h-screen">
      <main className="m-8 md:m-16">
        <h1>What is M3FR?</h1>
        <p className={clsx(contentStyle, '!mt-10')}>
          In 2018, the Texas General Land Office (GLO) partnered with Texas A&M University’s
          Institute for a Disaster Resilient Texas (IDRT) to conduct the Measuring, Mapping, and
          Managing Flood Risk in Texas, Part 1 (“M3FR1”) comprehensive planning study. M3FR, Part 1,
          developed The Damage Plain, a new flood risk visualization, to better map and communicate
          the reality of flood risk beyond the traditionally used Federal Emergency Management
          Agency’s 100-year floodplain mapping.
        </p>
        <p className={contentStyle}>
          In September 2023, GLO and IDRT launched the Measuring, Mapping, and Managing Flood Risk
          in Texas (“M3FR2”), a 3-year project that will improve flood risk communication by
          integrating multiple types of flood risk and hazard models. As part of this project,
          researchers at IDRT will develop a flood risk communication tool that results in
          actionable insights via improved risk perception. The completed M3FR2 will consist of the
          following:
        </p>
        <ul className={clsx(contentStyle, 'ml-4')}>
          <li>
            A synthesis of existing flood risk and hazard models and the information that they can
            produce.
          </li>
          <li>
            An understanding of the types of messaging and visualizations that will stimulate more
            resilient policies and behaviors.
          </li>
          <li>
            The development of a pilot flood risk communication tool that can be implemented at the
            local or regional level to help decision makers reduce the adverse impact of floods.
          </li>
        </ul>

        <h1 className="my-10">Contributors</h1>
        <div className="flex flex-wrap gap-14">
          {TEAM_MEMBERS.map((contributor, index) => (
            <div key={`contributo-${index}`}>
              <ContributorCard contributor={contributor} />
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default About;
