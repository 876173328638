import { EnvelopeIcon, DocumentTextIcon } from '@heroicons/react/24/solid';

const ContributorCard = ({ contributor }) => {
  const headshotDimensions = 'w-44 h-44 md:w-64 md:h-64';

  // TODO: collect the headshots and CVs for gerard and jeff
  return (
    <div className="flex flex-col items-center w-72">
      <img src={contributor.headshot} alt="" className={headshotDimensions}></img>
      <h2 className="mt-4">{contributor.name}</h2>
      <p className="italic mt-2">{contributor.title}</p>
      <p className="max-w-80 text-center">{contributor.affiliation}</p>
      <div className="flex gap-8 mt-4">
        <div className="bg-primary rounded-full p-2">
          <a
            href={`mailto:${contributor.email}`}
            target="_blank"
            rel="noreferrer"
            aria-label={`Email ${contributor.name}`}
          >
            <EnvelopeIcon className="text-white w-8" />
          </a>
        </div>
        {contributor.cv && (
          <div className="bg-primary rounded-full p-2">
            <a
              href={contributor.cv}
              target="_blank"
              rel="noreferrer"
              aria-label={`See ${contributor.name}'s curriculum`}
            >
              <DocumentTextIcon className="text-white w-8" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContributorCard;
