import { useNavigate } from 'react-router-dom';
import {
  HydraulicTooltip,
  HydrodynamicTooltip,
  HydrologicTooltip,
  NonPhysicsTooltip,
  ScaleInfoTooltip,
} from './general/Tooltips';
import {
  CheckIcon,
  InformationCircleIcon,
  CheckBadgeIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';

const checkBox = 'border border-primary w-6 h-6';

const CheckedBox = () => {
  return (
    <div className={clsx(checkBox, 'bg-primary')}>
      <CheckIcon className="text-white" />
    </div>
  );
};

const UncheckedBox = () => {
  return <div className={clsx(checkBox, 'bg-white')} />;
};

export const CatalogTable = ({ data }) => {
  const navigate = useNavigate();

  const columns = [
    [
      'Name',
      'Model Name',
      row => (
        <button
          className="link-button text-left"
          onClick={() => navigate(`/models/${row['Number']}`)}
        >
          {row['Model Name']}
        </button>
      ),
    ],
    [
      'Type',
      'Model Type',
      row => {
        let typeColor;
        let content;
        if (row['Model Type'] === 'Hydrodynamic') {
          typeColor = 'red';
          content = <HydrodynamicTooltip />;
        } else if (row['Model Type'] === 'Hydrologic') {
          typeColor = 'dodgerblue';
          content = <HydrologicTooltip />;
        } else if (row['Model Type'] === 'Hydraulic') {
          typeColor = 'mediumseagreen';
          content = <HydraulicTooltip />;
        } else {
          typeColor = 'goldenrod';
          content = <NonPhysicsTooltip />;
        }

        return (
          <>
            <button
              data-tooltip-id={row['Model Type']}
              className="flex gap-2 min-w-36 items-center border border-slate rounded-md p-2 hover:bg-gray-100 hover:cursor-default"
            >
              <svg height="10" width="10">
                <circle r="5" cx="5" cy="5" fill={typeColor} />
              </svg>
              {row['Model Type'] === 'Simplified/Non-Physics' ? 'Non-Physics' : row['Model Type']}
            </button>
            {content}
          </>
        );
      },
    ],
    [
      'Process',
      'Process',
      row => {
        const processes = row['Process'].split('; ');
        return (
          <div className="flex gap-10 mx-2">
            {processes.includes('Coastal') || processes.includes('Compound') ? (
              <CheckedBox />
            ) : (
              <UncheckedBox />
            )}
            {processes.includes('Fluvial') || processes.includes('Compound') ? (
              <CheckedBox />
            ) : (
              <UncheckedBox />
            )}
            {processes.includes('Pluvial') ? <CheckedBox /> : <UncheckedBox />}
          </div>
        );
      },
    ],
    [
      'Scale',
      'Scale',
      row => {
        const SCALES_INDEX = {
          0: 'Site-scale/Local',
          1: 'Watershed',
          2: 'Multi-watershed/Regional',
          3: 'Continental',
        };

        const scalesArray = row['Scale'].split('; ');
        const selected = 'text-white border rounded-xl border-green bg-green divide-x px-2.5';
        return (
          <div className="flex gap-8 mx-4">
            {Object.entries(SCALES_INDEX).map((_, index) => (
              <p
                key={`scale-${index}`}
                className={clsx(
                  'text-lg',
                  scalesArray.includes(SCALES_INDEX[index]) ? selected : 'text-primary mx-2.5',
                )}
              >
                {index + 1}
              </p>
            ))}
          </div>
        );
      },
    ],
    [
      'Phase',
      'Phase',
      row => {
        const phases = row['Phase'].split('; ');
        return (
          <ul>
            {phases.map((phase, index) => (
              <li key={`phase-${index}`}>{phase}</li>
            ))}
          </ul>
        );
      },
    ],
    [
      'NFIP Approved',
      'NFIP Approved',
      row => (
        <div className="flex justify-center">
          {row['NFIP Approved'] === 'Yes' ? (
            <CheckBadgeIcon className="w-8" />
          ) : (
            <XCircleIcon className="w-8 text-error" />
          )}
        </div>
      ),
    ],
    ['Developer', 'Developer'],
  ];

  return (
    <div className="border border-dark_blue rounded-md my-10">
      {data?.length === 0 ? (
        <div className="bg-white rounded-md">
          <p className="px-3 py-4">There are no models available at this time. Please try again.</p>
        </div>
      ) : (
        <div className="rounded-lg">
          <div className="overflow-auto">
            <table className="w-full">
              <thead className="text-white bg-primary">
                <tr>
                  {columns.map(column => (
                    <th key={column[0]} className="text-lg">
                      {column[0] === 'Scale' ? (
                        <div className="flex gap-4 items-center">
                          {column[0]}
                          <button data-tooltip-id={column[0]} aria-label="Scale column legend">
                            <InformationCircleIcon className="text-white w-6" />
                          </button>
                          <ScaleInfoTooltip />
                        </div>
                      ) : column[0] === 'Process' ? (
                        <div>
                          {column[0]}
                          <div className="flex gap-4 *:text-white *:text-sm *:mt-4">
                            <p>Coastal</p>
                            <p>Fluvial</p>
                            <p>Pluvial</p>
                          </div>
                        </div>
                      ) : (
                        column[0]
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr key={`row-${index}`} className="even:bg-primary-25 odd:bg-ice">
                    {columns.map(([_, key, renderFn]) => (
                      <td key={key} className={'align-middle'}>
                        {renderFn ? renderFn(row) : row[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
