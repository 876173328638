import texasAMLogo from '../../assets/tam-logo.svg';

const Footer = () => {
  return (
    <footer className="bottom-0 w-full flex justify-between bg-banner">
      <p className="flex items-center ml-2 md:ml-20 text-white">© All rights reserved</p>
      <a
        href="https://www.tamu.edu/index.html"
        target="_blank"
        rel="noreferrer"
        aria-label="Texas A&M University logo"
        className="mr-0 md:mr-10"
      >
        <img src={texasAMLogo} alt="" className="h-20" />
      </a>
    </footer>
  );
};

export default Footer;
