import clsx from 'clsx';
import { useState } from 'react';

const TextArea = ({ tabs }) => {
  const [activeTabValue, setActiveTabValue] = useState(1);

  return (
    <>
      {tabs && (
        <ul role="tablist" aria-label="Model details tabs" className="flex flex-wrap gap-4">
          {tabs.map(tab => (
            <li
              id={`tab-${tab.value}`}
              tabIndex={activeTabValue === tab.value ? '0' : '-1'}
              key={tab.value}
              role="tab"
              aria-selected={activeTabValue === tab.value ? 'true' : 'false'}
              aria-controls={`panel-${tab.value}`}
              className="list-none ml-0 -mb-[2px]"
            >
              <button
                onClick={() => setActiveTabValue(tab.value)}
                className={clsx(
                  'border-2 border-solid border-b-0 border-primary rounded-ss-lg rounded-se-lg py-2 px-6 text-lg uppercase bg-white',
                  activeTabValue !== tab.value
                    ? 'hover:bg-gray-200 font-normal'
                    : 'font-bold z-10 border-b-2 border-b-white',
                )}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      )}

      {tabs?.map(
        tab =>
          activeTabValue === tab.value && (
            <div
              key={tab.value}
              role="tabpanel"
              id={`panel-${tab.value}`}
              aria-labelledby={`tab-${tab.value}`}
              hidden={activeTabValue !== tab.value}
              className="border-2 border-solid border-primary rounded-e-lg rounded-es-lg overflow-y-scroll z-0 min-h-[800px] bg-white"
            >
              <div className="m-10">{tab.component}</div>
            </div>
          ),
      )}
    </>
  );
};

export default TextArea;
