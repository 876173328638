import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Button from './general/Button';
import clsx from 'clsx';
import Toggle from './general/Toggle';

const debounce = require('lodash.debounce');

export const Filters = ({ setSearch, setFilters }) => {
  const wrapperRef = useRef(null);
  const [menuOpened, setMenuOpened] = useState(false);

  // Hook that alerts clicks outside of the filters menu and closes the menu
  // Source: https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js:311-368
  const useOutsideAlerter = ref => {
    useEffect(() => {
      // Closes the menu if click outside of it
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuOpened(false);
        }
      };
      // Bind the event listener
      window.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const applyFilters = () => {
    // Checks if there's at least one filter selected and returns an array with all filters selected
    const filtersSelected = Object.entries(formik.values.filters)
      .filter(f => f[1])
      .map(f => f[0]);

    setFilters(filtersSelected);
    setMenuOpened(false);
  };

  const formik = useFormik({
    initialValues: {
      search: '',
      filters: {
        // types
        hydrodynamic: false,
        hydrologic: false,
        hydraulic: false,
        nonPhysics: false,
        // processes
        coastal: false,
        pluvial: false,
        fluvial: false,
        // scales
        local: false,
        watershed: false,
        regional: false,
        continental: false,
        // phases
        preparedness: false,
        mitigation: false,
        response: false,
        recovery: false,
        // NFIP-approved
        nfipApproved: false,
      },
    },
    onSubmit: applyFilters,
  });

  // Debounce here so the search only happens when a user stops typing for 250ms
  // Source: https://github.com/jaredpalmer/formik/discussions/3131
  const debouncedSearchFunction = useMemo(() => debounce(setSearch, 250), [setSearch]);

  useEffect(() => {
    debouncedSearchFunction(formik.values.search);
  }, [formik.values, debouncedSearchFunction]);

  const row = 'flex flex-col w-full mb-0 md:mb-8 md:flex-row';
  const column = 'flex flex-col basis-1/2 *:mb-2 mb-8 md:mb-0';
  const checkbox = 'flex gap-2 items-center';

  useOutsideAlerter(wrapperRef);

  return (
    <div className="flex flex-col gap-6 md:gap-0 md:flex-row">
      <div className="flex w-72 h-12">
        <input
          id="search"
          type="text"
          placeholder="Model name or developer"
          className="bg-search-icon bg-no-repeat bg-[center_left_10px] indent-5 w-64"
          value={formik.values.search}
          onChange={formik.handleChange}
        />
      </div>

      <button
        onClick={() => setMenuOpened(!menuOpened)}
        className="bg-primary text-white text-lg px-4 flex justify-center gap-4 h-12 items-center rounded-md"
      >
        Add Filters
        <ChevronDownIcon className={clsx('w-6 transition', menuOpened && 'rotate-180')} />
      </button>

      {menuOpened && (
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div
            ref={wrapperRef}
            className=" ml-0 right-6 p-10 border-primary border-2 text-xl shadow rounded-lg bg-white z-10 md:absolute md:max-w-[600px] md:right-16 md:my-16"
          >
            <div className="flex flex-wrap">
              <div className={row}>
                <div className={column}>
                  <h2>Type</h2>
                  <div className={checkbox}>
                    <input
                      id="filters.hydrodynamic"
                      type="checkbox"
                      checked={formik.values.filters.hydrodynamic}
                      onChange={formik.handleChange}
                    />
                    Hydrodynamic
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.hydrologic"
                      type="checkbox"
                      checked={formik.values.filters.hydrologic}
                      onChange={formik.handleChange}
                    />
                    Hydrologic
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.hydraulic"
                      type="checkbox"
                      checked={formik.values.filters.hydraulic}
                      onChange={formik.handleChange}
                    />
                    Hydraulic
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.nonPhysics"
                      type="checkbox"
                      checked={formik.values.filters.nonPhysics}
                      onChange={formik.handleChange}
                    />
                    Non-Physics
                  </div>
                </div>

                <div className={column}>
                  <h2>Process</h2>
                  <div className={checkbox}>
                    <input
                      id="filters.coastal"
                      type="checkbox"
                      checked={formik.values.filters.coastal}
                      onChange={formik.handleChange}
                    />
                    Coastal
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.fluvial"
                      type="checkbox"
                      checked={formik.values.filters.fluvial}
                      onChange={formik.handleChange}
                    />
                    Fluvial
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.pluvial"
                      type="checkbox"
                      checked={formik.values.filters.pluvial}
                      onChange={formik.handleChange}
                    />
                    Pluvial
                  </div>
                </div>
              </div>

              <div className={row}>
                <div className={column}>
                  <h2>Scale</h2>
                  <div className={checkbox}>
                    <input
                      id="filters.local"
                      type="checkbox"
                      checked={formik.values.filters.local}
                      onChange={formik.handleChange}
                    />
                    Site-scale/Local
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.watershed"
                      type="checkbox"
                      checked={formik.values.filters.watershed}
                      onChange={formik.handleChange}
                    />
                    Watershed
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.regional"
                      type="checkbox"
                      checked={formik.values.filters.regional}
                      onChange={formik.handleChange}
                    />
                    Multi-washed/Regional
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.continental"
                      type="checkbox"
                      checked={formik.values.filters.continental}
                      onChange={formik.handleChange}
                    />
                    Continental
                  </div>
                </div>

                <div className={column}>
                  <h2>Phase</h2>
                  <div className={checkbox}>
                    <input
                      id="filters.preparedness"
                      type="checkbox"
                      checked={formik.values.filters.preparedness}
                      onChange={formik.handleChange}
                    />
                    Preparedness
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.mitigation"
                      type="checkbox"
                      checked={formik.values.filters.mitigation}
                      onChange={formik.handleChange}
                    />
                    Mitigation
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.response"
                      type="checkbox"
                      checked={formik.values.filters.response}
                      onChange={formik.handleChange}
                    />
                    Response
                  </div>
                  <div className={checkbox}>
                    <input
                      id="filters.recovery"
                      type="checkbox"
                      checked={formik.values.filters.recovery}
                      onChange={formik.handleChange}
                    />
                    Recovery
                  </div>
                </div>
              </div>
            </div>

            <div className={clsx(checkbox, 'gap-4 mb-8')}>
              <h2>NFIP-Approved</h2>
              <Toggle
                id="filters.nfipApproved"
                value={formik.values.filters.nfipApproved}
                onChange={formik.handleChange}
              />
            </div>

            <div className="flex flex-col-reverse gap-8 items-start md:flex-row md:justify-between md:items-center md:gap-0">
              <Button type="reset" className="link-button !font-medium hover:underline-offset-8">
                Clear filters
              </Button>
              <Button type="submit">Apply filters</Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
