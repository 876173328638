import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const Resources = ({ model }) => {
  const caseStudies = model['Case Studies'].split(';');
  const caseStudiesDOI = model['DOI for case studies'].split('\n');
  const dashboards = model['Dashboards'].split(';');
  const dashboardsURLs = model['Dashboard URL'].split('\n');

  const titleStyle = 'mb-4 font-bold';
  const hyperlinkStyle = 'wordwrap text-hyperlink font-normal';

  return (
    <div className="*:my-10">
      <div>
        <h2 className={titleStyle}>Case Studies:</h2>
        {!caseStudies[0] ? (
          '--'
        ) : caseStudies.length > 1 ? (
          <ul>
            {caseStudies.map((caseStudy, index) => (
              <li key={`case-study-${index}`} className="my-4 ml-6">
                {caseStudy}.{' '}
                <a
                  href={caseStudiesDOI[index]}
                  target="_blank"
                  rel="noreferrer"
                  className={hyperlinkStyle}
                >
                  {caseStudiesDOI[index]}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div>
            {caseStudies[0]}
            {` `}
            <a
              href={caseStudiesDOI[0]}
              target="_blank"
              rel="noreferrer"
              className="text-hyperlink font-normal"
            >
              {caseStudiesDOI[0]}
            </a>
          </div>
        )}
      </div>

      <div>
        <h2 className={titleStyle}>Dashboards:</h2>
        {!dashboards[0] ? (
          '--'
        ) : dashboards.length > 1 ? (
          <ul>
            {dashboards.map((dashboard, index) => (
              <div key={`dashboard-${index}`} className="flex gap-4 items-center ml-4">
                <li>{dashboard}</li>
                <a href={dashboardsURLs[index]} target="_blank" rel="noreferrer">
                  <ArrowTopRightOnSquareIcon className="w-6 text-primary stroke-2" />
                </a>
              </div>
            ))}
          </ul>
        ) : (
          <div className="flex gap-4 items-center">
            {dashboards[0]}
            <a href={dashboardsURLs[0]} target="_blank" rel="noreferrer">
              <ArrowTopRightOnSquareIcon className="w-6 text-primary stroke-2" />
            </a>
          </div>
        )}
      </div>

      <div>
        <h2 className={titleStyle}>Website:</h2>
        {(
          <a
            href={model['Official website']}
            target="_blank"
            rel="noreferrer"
            className={hyperlinkStyle}
          >
            {model['Official website']}
          </a>
        ) || '--'}
      </div>
    </div>
  );
};

export default Resources;
