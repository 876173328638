import { SET_SLIDE_SHOW_MODAL } from '../actions/modals';

const defaultState = {
  showSlideShowModal: false,
  modelImages: null,
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SLIDE_SHOW_MODAL:
      return {
        ...state,
        showSlideShowModal: action.payload.showModal,
        modelImages: action.payload.modelImages,
      };
    default:
      return state;
  }
};

export default modalsReducer;
