import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import useGetAppConsts from './hooks/useGetAppConsts';
import HeaderBanner from './components/general/HeaderBanner';
import Header from './components/general/Header';
import Main from './components/Main';
import ModelPage from './components/ModelPage';
import About from './components/About';
import Methodology from './components/Methodology';
import Footer from './components/general/Footer';
import ScrollToTop from './components/general/ScrollToTop';

import store from './store';

import './style/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { SlideShow } from './components/modals/SlideShow';

const Router = () => {
  useGetAppConsts();

  return (
    <BrowserRouter>
      <SlideShow />
      <HeaderBanner />
      <Header />
      <Routes>
        <Route path="/" exact={true} element={<Main />} />
        <Route path="/models/:id" exact={true} element={<ModelPage />} />
        <Route path="/about" exact={true} element={<About />} />
        <Route path="/methodology" exact={true} element={<Methodology />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
