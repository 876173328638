import { SET_MODEL_DATA } from '../actions/general';

const defaultState = {
  modelData: null,
};

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MODEL_DATA:
      return {
        ...state,
        modelData: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
