import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_ROOT = '/api';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    // Add endpoints here
    // See: https://redux-toolkit.js.org/rtk-query/usage/queries
  }),
});
