import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { usePapaParse } from 'react-papaparse';
import { clearStore, setModelData } from '../store/actions/general';
import { errorToast } from '../utils/toast';
import { SESSION_EXP_STORAGE_KEY } from '../constants/general';

const useGetAppConsts = () => {
  const dispatch = useDispatch();
  const { readString } = usePapaParse();

  useEffect(() => {
    const getAppConsts = async () => {
      const getModels = async () => {
        const response = await fetch(`${process.env.PUBLIC_URL}/data/models-data.csv`);
        const text = await response.text();
        const modelsInfo = readString(text, {
          header: true,
          skipEmptyLines: true,
          complete: results => {
            return results.data;
          },
        });
        return modelsInfo.data;
      };

      try {
        const allModels = await getModels();
        dispatch(setModelData(allModels));
      } catch (error) {
        errorToast('Something went wrong.');
        dispatch(clearStore());
        localStorage.removeItem(SESSION_EXP_STORAGE_KEY);
      }
    };

    getAppConsts();
  }, [dispatch, readString]);
};

export default useGetAppConsts;
