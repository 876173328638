import clsx from 'clsx';

// Source: https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
const Toggle = ({ id, value, onChange }) => {
  return (
    <label className="relative inline-block w-14 h-8">
      <input
        id={id}
        type="checkbox"
        className="opacity-0 w-0 h-0 focus:shadow-current"
        checked={value}
        onChange={onChange}
      />
      <span
        className={clsx(
          'absolute cursor-pointer top-0 left-0 bottom-0 bg-[#ccc] right-0 transition-all rounded-3xl before:absolute before:bg-white before:h-6 before:w-6 before:left-1 before:bottom-1 before:transition-all before:rounded-3xl',
          value && '!bg-[#2196F3] before:translate-x-6',
        )}
      ></span>
    </label>
  );
};

export default Toggle;
