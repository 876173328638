import { useDispatch } from 'react-redux';
import { SectionLoader } from '../general/Loaders';
import { setSlideShowModal } from '../../store/actions/modals';

const Gallery = ({ modelImages, isLoading }) => {
  const dispatch = useDispatch();
  if (isLoading) {
    return (
      <div className="flex flex-col items-center">
        <SectionLoader />
        <p className="mt-4">Loading model images...</p>
      </div>
    );
  }

  return !modelImages.length ? (
    <p>There are no images of this model at this time. Please try again later.</p>
  ) : (
    <div className="flex flex-wrap gap-10">
      {modelImages.map(({ index, title, caption, image }) => (
        <div key={index} className="max-w-[700px] *:my-4">
          <img
            src={image}
            alt={title}
            className="hover:cursor-pointer"
            onClick={() => dispatch(setSlideShowModal(true, modelImages))}
          />
          <p className="text-lg">{caption}</p>
        </div>
      ))}
    </div>
  );
};

export default Gallery;
