const StrengthsAndWeaknesses = ({ model }) => {
  const strengths = model['Strengths'].split(';');
  const weaknesses = model['Weaknesses'].split(';');
  const recommendations = model['Recommendations'].split(';');

  const titleStyle = 'mb-4 font-bold';

  return (
    <div className="*:my-10">
      <div>
        <h2 className={titleStyle}>Strengths:</h2>
        {strengths.length === 0 ? (
          '--'
        ) : strengths.length > 1 ? (
          <ul>
            {strengths.map((strength, index) => (
              <li key={`strength-${index}`} className="my-4 ml-4">
                {strength}
              </li>
            ))}
          </ul>
        ) : (
          strengths[0] || '--'
        )}
      </div>

      <div>
        <h2 className={titleStyle}>Weaknesses:</h2>
        {weaknesses.length === 0 ? (
          '--'
        ) : weaknesses.length > 1 ? (
          <ul>
            {weaknesses.map((weakness, index) => (
              <li key={`weakness-${index}`} className="my-4 ml-4">
                {weakness}
              </li>
            ))}
          </ul>
        ) : (
          weaknesses[0] || '--'
        )}
      </div>

      <div>
        <h2 className={titleStyle}>Recommendations:</h2>
        {recommendations.length === 0 ? (
          '--'
        ) : recommendations.length > 1 ? (
          <ul>
            {recommendations.map((recommendation, index) => (
              <li key={`recommendation-${index}`} className="my-4 ml-6">
                {recommendation}
              </li>
            ))}
          </ul>
        ) : (
          recommendations[0] || '--'
        )}
      </div>
    </div>
  );
};

export default StrengthsAndWeaknesses;
