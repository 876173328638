import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { ModalWrapper } from './ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setSlideShowModal } from '../../store/actions/modals';
import { modelImagesSelector, showSlideShowModalSelector } from '../../store/selectors/modals';

// Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

export const SlideShow = () => {
  const showModal = useSelector(showSlideShowModalSelector);
  const modelImages = useSelector(modelImagesSelector);
  const dispatch = useDispatch();

  const swiperRef = useRef();

  if (!showModal) {
    return null;
  }

  return (
    <ModalWrapper onClose={() => dispatch(setSlideShowModal(false))}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#324755',
        }}
        loop={true}
        spaceBetween={10}
        onBeforeInit={swiper => {
          swiperRef.current = swiper;
        }}
        modules={[FreeMode, Navigation]}
      >
        {modelImages.map(({ index, title, caption, image }) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col justify-center items-center *:my-4 my-6">
              <img src={image} alt={title} />
              <p className="md:max-w-[800px] text-md md:text-lg">{caption}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {modelImages.length > 1 && (
        <div className="w-full">
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-10">
            {modelImages.map(({ index, title, image }) => (
              <img
                key={index}
                src={image}
                alt={title}
                className="opacity-40 max-h-10 max-w-10 md:max-h-40 md:max-w-40 hover:opacity-100 hover:cursor-pointer min-w-0"
                onClick={() => swiperRef.current?.slideTo(index)}
              />
            ))}
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};
