import { useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import FocusTrap from 'focus-trap-react';

export const ModalWrapper = ({ children, onClose, closeOnOuterClick, trapFocus = true }) => {
  useEffect(() => {
    const closeOnEscapePress = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    // Listens to browser back/forward button click and closes modal
    window.addEventListener('popstate', e => {
      onClose();
    });
    window.addEventListener('keydown', closeOnEscapePress);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
    };
  }, [onClose]);

  const contents = (
    <div
      className="relative m-2 max-h-[90%] w-[90%] overflow-x-auto overflow-y-scroll rounded-sm bg-white p-10 text-dark_blue md:w-[85%]"
      onClick={e => e.stopPropagation()}
    >
      <div className="flex justify-end">
        <button
          aria-label="close"
          className="flex cursor-pointer items-center gap-1 font-bold"
          onClick={onClose}
          type="button"
        >
          <div>close</div>
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
      {children}
    </div>
  );

  return (
    <div
      className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(100,100,100,0.45)]"
      onClick={() => (closeOnOuterClick ? onClose() : null)}
    >
      {trapFocus ? <FocusTrap>{contents}</FocusTrap> : contents}
    </div>
  );
};
