import texasAMLogo from '../../assets/tam-logo.svg';

const HeaderBanner = () => {
  return (
    <div className="w-full bg-banner flex justify-end">
      <a
        href="https://www.tamu.edu/index.html"
        target="_blank"
        rel="noreferrer"
        aria-label="Texas A&M University logo"
        className="mx-0 md:mx-10"
      >
        <img src={texasAMLogo} alt="" className="h-20" />
      </a>
    </div>
  );
};

export default HeaderBanner;
